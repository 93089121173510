export default function getColor (meSats) {
  if (!meSats || meSats <= 10) {
    return 'var(--bs-secondary)'
  }

  const idx = Math.min(
    Math.floor((Math.log(meSats) / Math.log(10000)) * (Rainbow.length - 1)),
    Rainbow.length - 1)
  return Rainbow[idx]
}

const Rainbow = [
  '#f6911d',
  '#f6921e',
  '#f6931f',
  '#f6941f',
  '#f69420',
  '#f69520',
  '#f69521',
  '#f69621',
  '#f69622',
  '#f69722',
  '#f69723',
  '#f69823',
  '#f69824',
  '#f69924',
  '#f69925',
  '#f69a25',
  '#f79a25',
  '#f79b26',
  '#f79c26',
  '#f79c27',
  '#f79d27',
  '#f79d28',
  '#f79e28',
  '#f79e29',
  '#f79f29',
  '#f79f2a',
  '#f7a02a',
  '#f7a02b',
  '#f7a12b',
  '#f7a12c',
  '#f7a22c',
  '#f7a32d',
  '#f7a42e',
  '#f7a52e',
  '#f7a52f',
  '#f7a62f',
  '#f7a630',
  '#f7a730',
  '#f7a731',
  '#f7a831',
  '#f7a832',
  '#f7a932',
  '#f7a933',
  '#f7aa33',
  '#f7aa34',
  '#f7ab34',
  '#f7ac35',
  '#f8ac35',
  '#f8ad36',
  '#f8ae36',
  '#f8ae37',
  '#f8af38',
  '#f8b038',
  '#f8b039',
  '#f8b139',
  '#f8b13a',
  '#f8b23a',
  '#f8b23b',
  '#f8b33b',
  '#f8b33c',
  '#f8b43c',
  '#f8b53d',
  '#f8b63e',
  '#f8b73f',
  '#f8b83f',
  '#f8b840',
  '#f8b940',
  '#f8b941',
  '#f8ba41',
  '#f8ba42',
  '#f8bb42',
  '#f8bb43',
  '#f8bc43',
  '#f8bd44',
  '#f8bd45',
  '#f8be45',
  '#f8bf46',
  '#f9bf46',
  '#f9c047',
  '#f9c147',
  '#f9c148',
  '#f9c248',
  '#f9c249',
  '#f9c349',
  '#f9c34a',
  '#f9c44a',
  '#f9c44b',
  '#f9c54b',
  '#f9c54c',
  '#f9c64c',
  '#f9c64d',
  '#f9c74d',
  '#f9c84e',
  '#f9c94f',
  '#f9ca4f',
  '#f9ca50',
  '#f9cb50',
  '#f9cb51',
  '#f9cc51',
  '#f9cc52',
  '#f9cd52',
  '#f9cd53',
  '#f9ce53',
  '#f9ce54',
  '#f9cf54',
  '#f9cf55',
  '#f9d055',
  '#f9d156',
  '#fad156',
  '#fad256',
  '#fad257',
  '#fad358',
  '#fad458',
  '#fad459',
  '#fad559',
  '#fad55a',
  '#fad65a',
  '#fad65b',
  '#fad75b',
  '#fad75c',
  '#fad85c',
  '#fad95d',
  '#fada5e',
  '#f9da5e',
  '#f9d95d',
  '#f8d95d',
  '#f7d95d',
  '#f7d85c',
  '#f6d85c',
  '#f6d75b',
  '#f5d75b',
  '#f4d75b',
  '#f4d65a',
  '#f3d65a',
  '#f2d65a',
  '#f2d559',
  '#f1d559',
  '#f1d558',
  '#f0d458',
  '#efd458',
  '#efd457',
  '#eed357',
  '#edd357',
  '#edd256',
  '#ecd256',
  '#ebd255',
  '#ebd155',
  '#ead155',
  '#ead154',
  '#e9d054',
  '#e8d054',
  '#e8d053',
  '#e7cf53',
  '#e6cf52',
  '#e6ce52',
  '#e5ce52',
  '#e5ce51',
  '#e4cd51',
  '#e3cd51',
  '#e3cd50',
  '#e2cc50',
  '#e1cc4f',
  '#e0cb4f',
  '#dfcb4e',
  '#deca4e',
  '#deca4d',
  '#ddc94d',
  '#dcc94d',
  '#dcc94c',
  '#dbc84c',
  '#dac84b',
  '#d9c74b',
  '#d9c74a',
  '#d8c74a',
  '#d7c64a',
  '#d7c649',
  '#d6c549',
  '#d5c548',
  '#d4c448',
  '#d3c447',
  '#d2c347',
  '#d2c346',
  '#d1c346',
  '#d0c245',
  '#cfc245',
  '#cec144',
  '#cdc044',
  '#ccc043',
  '#cbbf42',
  '#cabf42',
  '#c9be41',
  '#c8be41',
  '#c7bd40',
  '#c6bc3f',
  '#c5bc3f',
  '#c4bb3e',
  '#c3bb3e',
  '#c2ba3d',
  '#c1ba3d',
  '#c0b93c',
  '#bfb93b',
  '#bfb83b',
  '#beb83b',
  '#bdb73a',
  '#bcb73a',
  '#bbb639',
  '#bab638',
  '#bab538',
  '#b9b538',
  '#b8b537',
  '#b8b437',
  '#b7b437',
  '#b6b336',
  '#b5b335',
  '#b4b235',
  '#b3b234',
  '#b3b134',
  '#b2b134',
  '#b1b133',
  '#b1b033',
  '#b0b032',
  '#afb032',
  '#afaf32',
  '#aeaf31',
  '#aeae31',
  '#adae31',
  '#acae30',
  '#acad30',
  '#abad30',
  '#aaad2f',
  '#aaac2f',
  '#a9ac2e',
  '#a8ac2e',
  '#a8ab2e',
  '#a7ab2d',
  '#a7aa2d',
  '#a6aa2d',
  '#a5aa2c',
  '#a5a92c',
  '#a4a92b',
  '#a3a92b',
  '#a3a82b',
  '#a2a82a',
  '#a1a72a',
  '#a0a729',
  '#9fa628',
  '#9ea628',
  '#9ea528',
  '#9da527',
  '#9ca527',
  '#9ca427',
  '#9ba426',
  '#9aa325',
  '#99a325',
  '#98a224',
  '#97a224',
  '#97a124',
  '#96a123',
  '#95a022',
  '#94a022',
  '#939f21',
  '#929f21',
  '#919e20',
  '#909e20',
  '#8f9d1f',
  '#8e9c1e',
  '#8d9c1e',
  '#8c9b1d',
  '#8b9b1d',
  '#8a9a1c',
  '#899a1b',
  '#88991b',
  '#87981a',
  '#86981a',
  '#859719',
  '#849719',
  '#849718',
  '#839618',
  '#829617',
  '#819517',
  '#809516',
  '#7f9416',
  '#7f9415',
  '#7e9315',
  '#7d9315',
  '#7d9314',
  '#7c9214',
  '#7b9213',
  '#7a9113',
  '#7a9112',
  '#799112',
  '#789012',
  '#789011',
  '#778f11',
  '#768f10',
  '#758e10',
  '#748e0f',
  '#738d0f',
  '#738d0e',
  '#728d0e',
  '#718c0e',
  '#718c0d',
  '#708c0d',
  '#708b0d',
  '#6f8b0c',
  '#6e8a0c',
  '#6e8a0b',
  '#6d8a0b',
  '#6c890b',
  '#6c890a',
  '#6b890a',
  '#6b880a',
  '#6a8809',
  '#698809',
  '#698708',
  '#688708',
  '#678608',
  '#678607',
  '#668607',
  '#658507',
  '#658506',
  '#648506',
  '#648405',
  '#638405',
  '#628405',
  '#628304',
  '#618304',
  '#608304',
  '#608203',
  '#5f8203',
  '#5f8102',
  '#5e8102',
  '#5d8102',
  '#5d8001',
  '#5c8001',
  '#5c8002',
  '#5b8003',
  '#5b8004',
  '#5a8005',
  '#5a8006',
  '#598006',
  '#598007',
  '#598008',
  '#588009',
  '#58800a',
  '#57800b',
  '#57800c',
  '#56800c',
  '#56800d',
  '#56800e',
  '#55800f',
  '#558010',
  '#548011',
  '#548012',
  '#538013',
  '#538014',
  '#528015',
  '#528016',
  '#518017',
  '#518018',
  '#507f19',
  '#507f1a',
  '#4f7f1b',
  '#4f7f1c',
  '#4e7f1d',
  '#4e7f1e',
  '#4d7f1f',
  '#4d7f20',
  '#4d7f21',
  '#4c7f22',
  '#4b7f23',
  '#4b7f24',
  '#4b7f25',
  '#4a7f25',
  '#4a7f26',
  '#4a7f27',
  '#497f28',
  '#487f29',
  '#487f2a',
  '#487f2b',
  '#477f2b',
  '#477f2c',
  '#477f2d',
  '#467f2e',
  '#467f2f',
  '#457f30',
  '#457f31',
  '#447f31',
  '#447f32',
  '#447f33',
  '#437f34',
  '#437f35',
  '#427f36',
  '#427f37',
  '#417f38',
  '#417f39',
  '#407f3a',
  '#407f3b',
  '#3f7f3c',
  '#3f7f3d',
  '#3e7f3e',
  '#3e7f3f',
  '#3d7f40',
  '#3d7f41',
  '#3c7f42',
  '#3c7f43',
  '#3c7f44',
  '#3b7f44',
  '#3b7f45',
  '#3b7f46',
  '#3a7f47',
  '#397e48',
  '#397e49',
  '#397e4a',
  '#387e4a',
  '#387e4b',
  '#387e4c',
  '#377e4d',
  '#367e4e',
  '#367e4f',
  '#367e50',
  '#357e50',
  '#357e51',
  '#357e52',
  '#347e53',
  '#347e54',
  '#337e55',
  '#337e56',
  '#327e56',
  '#327e57',
  '#327e58',
  '#317e59',
  '#317e5a',
  '#307e5b',
  '#307e5c',
  '#2f7e5c',
  '#2f7e5d',
  '#2f7e5e',
  '#2e7e5f',
  '#2e7e60',
  '#2d7e61',
  '#2d7e62',
  '#2d7e63',
  '#2c7e63',
  '#2c7e64',
  '#2b7e65',
  '#2b7e66',
  '#2a7e67',
  '#2a7e68',
  '#2a7e69',
  '#297e69',
  '#297e6a',
  '#287e6b',
  '#287e6c',
  '#277e6d',
  '#277e6e',
  '#277e6f',
  '#267e6f',
  '#267e70',
  '#267e71',
  '#257e72',
  '#247e73',
  '#247e74',
  '#247e75',
  '#237e75',
  '#237e76',
  '#237e77',
  '#227d78',
  '#217d79',
  '#217d7a',
  '#217d7b',
  '#207d7b',
  '#207d7c',
  '#207d7d',
  '#1f7d7e',
  '#1f7d7f',
  '#1e7d80',
  '#1e7d81',
  '#1d7d82',
  '#1d7d83',
  '#1c7d84',
  '#1c7d85',
  '#1b7d86',
  '#1b7d87',
  '#1a7d88',
  '#1a7d89',
  '#197d8a',
  '#197d8b',
  '#187d8c',
  '#187d8d',
  '#187d8e',
  '#177d8e',
  '#177d8f',
  '#167d90',
  '#167d91',
  '#157d92',
  '#157d93',
  '#157d94',
  '#147d94',
  '#147d95',
  '#147d96',
  '#137d97',
  '#127d98',
  '#127d99',
  '#127d9a',
  '#117d9a',
  '#117d9b',
  '#117d9c',
  '#107d9d',
  '#0f7d9e',
  '#0f7d9f',
  '#0f7da0',
  '#0e7da1',
  '#0e7da2',
  '#0d7da3',
  '#0d7da4',
  '#0c7da5',
  '#0c7da6',
  '#0b7ca7',
  '#0b7ca8',
  '#0a7ca9',
  '#0a7caa',
  '#097cab',
  '#097cac',
  '#087cad',
  '#087cae',
  '#077caf',
  '#077cb0',
  '#067cb1',
  '#067cb2',
  '#067cb3',
  '#057cb3',
  '#057cb4',
  '#047cb5',
  '#047cb6',
  '#037cb7',
  '#037cb8',
  '#037cb9',
  '#027cb9',
  '#027cba',
  '#017cbb',
  '#017cbc',
  '#007cbd',
  '#007cbe',
  '#017cbe',
  '#017bbe',
  '#027bbf',
  '#037abf',
  '#047ac0',
  '#0479c0',
  '#0579c0',
  '#0679c0',
  '#0678c0',
  '#0778c1',
  '#0777c1',
  '#0877c1',
  '#0976c1',
  '#0a76c2',
  '#0b75c2',
  '#0c75c3',
  '#0c74c3',
  '#0d74c3',
  '#0e73c3',
  '#0f73c4',
  '#1072c4',
  '#1172c4',
  '#1171c5',
  '#1271c5',
  '#1370c5',
  '#1470c6',
  '#146fc6',
  '#156fc6',
  '#166ec6',
  '#166ec7',
  '#176ec7',
  '#186dc7',
  '#196dc8',
  '#196cc8',
  '#1a6cc8',
  '#1b6bc8',
  '#1b6bc9',
  '#1c6bc9',
  '#1d6ac9',
  '#1e6ac9',
  '#1e69ca',
  '#1f69ca',
  '#2068ca',
  '#2068cb',
  '#2167cb',
  '#2267cb',
  '#2366cb',
  '#2366cc',
  '#2466cc',
  '#2465cc',
  '#2565cc',
  '#2665cc',
  '#2664cd',
  '#2764cd',
  '#2863cd',
  '#2863ce',
  '#2963ce',
  '#2962ce',
  '#2a62ce',
  '#2b62ce',
  '#2b61cf',
  '#2c61cf',
  '#2d60cf',
  '#2e5fd0',
  '#2f5fd0',
  '#305ed0',
  '#305ed1',
  '#315ed1',
  '#315dd1',
  '#325dd1',
  '#335cd2',
  '#345cd2',
  '#355bd2',
  '#355bd3',
  '#365bd3',
  '#365ad3',
  '#375ad3',
  '#3859d4',
  '#3959d4',
  '#3a58d4',
  '#3b57d5',
  '#3c57d5',
  '#3c56d5',
  '#3d56d6',
  '#3e56d6',
  '#3e55d6',
  '#3f55d6',
  '#4054d7',
  '#4154d7',
  '#4253d7',
  '#4353d8',
  '#4352d8',
  '#4452d8',
  '#4551d9',
  '#4651d9',
  '#4750d9',
  '#484fda',
  '#494fda',
  '#494eda',
  '#4a4edb',
  '#4b4ddb',
  '#4c4ddb',
  '#4d4cdc',
  '#4e4cdc',
  '#4e4bdc',
  '#4f4bdc',
  '#504bdd',
  '#504add',
  '#514add',
  '#5249de',
  '#5348de',
  '#5448de',
  '#5547df',
  '#5647df',
  '#5646df',
  '#5746df',
  '#5746e0',
  '#5845e0',
  '#5945e0',
  '#5a44e1',
  '#5b44e1',
  '#5b43e1',
  '#5c43e1',
  '#5c43e2',
  '#5d42e2',
  '#5e42e2',
  '#5f41e3',
  '#6040e3',
  '#6140e3',
  '#613fe4',
  '#623fe4',
  '#633fe4',
  '#633ee4',
  '#643ee4',
  '#643ee5',
  '#653de5',
  '#663de5',
  '#663ce6',
  '#673ce6',
  '#683ce6',
  '#683be6',
  '#693be6',
  '#693be7',
  '#6a3ae7',
  '#6b3ae7',
  '#6c39e7',
  '#6c39e8',
  '#6d38e8',
  '#6e38e8',
  '#6e37e9',
  '#6f37e9',
  '#7036e9',
  '#7136e9',
  '#7136ea',
  '#7235ea',
  '#7335ea',
  '#7334ea',
  '#7434eb',
  '#7533eb',
  '#7633eb',
  '#7633ec',
  '#7732ec',
  '#7832ec',
  '#7831ec',
  '#7931ed',
  '#7a30ed',
  '#7b30ed',
  '#7b2fee',
  '#7c2fee',
  '#7d2eee',
  '#7e2eef',
  '#7f2def',
  '#802def',
  '#802cef',
  '#812cf0',
  '#822bf0',
  '#832bf1',
  '#842af1',
  '#852af1',
  '#8529f1',
  '#8629f2',
  '#8628f2',
  '#8728f2',
  '#8828f2',
  '#8827f2',
  '#8927f3',
  '#8a26f3',
  '#8b26f4',
  '#8b25f4',
  '#8c25f4'
]
